import * as React from "react"
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../components/layoutEn"
import Warehouses from "../components/warehouses"
import { Link } from "gatsby"

import documentos from "../images/documentos.svg"
import paquetes from "../images/paquetes.svg"
import courier from "../images/impo-courier.png"
import cargaExpandedImg from "../images/logistica-carga-expanded.png"
import estrella from "../images/estrella-bca.svg"
import cobertura from "../pdf/IMPO-COBERTURA-GEOGRAFICA-Y-TIEMPOS-DE-TRANSITO-2022.pdf"

const ImportacionPage = () => {
  const [courierExpand, setCourierExpand] = React.useState(false)
  const [limitsExpand, setLimitsExpand] = React.useState(false)
  const [cargaExpand, setCargaExpand ] = React.useState(false)

  return (
  <Layout
	seccion="importacion"	
	title="Import"	
	linkTo="courier"
	>		
		<div id="courier" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-12 col-sm-6">
					<div className={"expTxt bgRed "+(courierExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-12 col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>International <br/>
								Courier Service </h2></div>		
								
								{/*<p className="text-white">Servicio de Transporte Aéreo internacional puerta a puerta<br/> de cualquier parte del mundo de documentos y paquetes<br/> hasta 50kg de peso bruto y hasta Usd 3000 de valor fob.</p> */}

								<p
								style={{ marginRight: '30px' }}
								className="text-white">Door-to-door international air transport service of documents and packages up to 50 kg. and USD 1000 FOB value.</p>
								
								<div id="expandCourier" className={"expand text-white "+(courierExpand ? 'invisible' : '')}
									onClick={() => setCourierExpand(!courierExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									MORE
								</div>
							</div>
					</div>
				</div>
				
					<div className={"col-sm-6 expImg courier"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={courierExpand ? 'expanded' : ''}></div>
					</div>
			</div>
			<AnimatePresence initial={false}>
				{courierExpand && (
				  <motion.section
					key="content"
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
					  open: { opacity: 1, height: "auto" },
					  collapsed: { opacity: 0, height: 0 }
					}}
					transition={{ duration: 0.5 }}
				  >
						<div className="row">
							<div className="col-12 col-sm-6 serviceExpand pr-0 " style={{minHeight: '200px'}}>
								<div className="bgRed d-flex flex-column">
									<div className="leftExpand">
										<div className="courierTitle d-flex align-items-start">
											<img src={paquetes} />
											<h2 className="text-white">International Document  <br/>Courier Service </h2>
										</div>
										
										<p>Door-to-door international air transport service for documents.
										
										{/*<p>Es un servicio de importación vía aérea puerta a puerta, desarrollado para envíos de documentos.  */}
										
										<br/>
										
										We provide global, express and cost-effective pick-up services at record transit times.
										</p>
										
										{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>*/}
										
										<p>Need more information?</p>
										<p className="requisitos"><a href="mailTo:impo@raiconet.com" target="_blank">ASK HERE</a></p>
										
										
										{/*
										<p>Para realizar una cotización envíe un mail a<br/>
										impo@raiconet.com con los siguientes datos:
										</p>
										
										<ul>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
											Origen.</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Peso (Kg).</li>
											<li><svg
											style={{
												verticalAlign: 'top',
												marginTop: '4px'
											}}										
											xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
											<span className="d-inline-block">Medidas (cm) o Peso Volumétrico<br/>
											(Largo x alto x ancho/5000).</span></li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Valor FOB (USD).</li>
											<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Descripción del contenido.</li>
										</ul>
										*/}
									</div>
									<div style={{marginTop: 'auto'}}><img src={courier} className="imgExpandLeft"  /></div>
								</div>
							</div>
							<div className="col-12 col-sm-6 serviceExpand pl-0">
								<div className="bgRed">
									<div className="courierTitle d-flex align-items-start">
										<img src={documentos} />
										<h2 className="text-white">International Packages  <br/>Courier Service</h2>
									</div>

									<p>Door-to-door international air transport service for packages up to 50 kg. and USD 1000 FOB value.</p>

                                    <p>Solid international agreements allow us to provide global, express and cost-effective pick-up services for your shipments.</p>
									
									{/* <p>Es un servicio de importación vía aérea
									puerta a puerta, diseñado para envíos urgentes que
									no superan los 50 kilos ni los USD 3.000 de valor FOB. </p>

									{/*<p>NO ES NECESARIO ESTAR INSCRIPTO COMO IMPORTADOR.</p>

									*/}
									
									{/*<p>Máxima calidad y servicio con menos tiempo de tránsito.
									Coordinamos la recolección inmediata.
									Contamos con vuelos diarios y directos desde Miami,
									Madrid y principales aeropuertos de Oriente.</p>
									
									
									*/}
									

									<p>You can order yous suppliers to deliver your purchases to our warehouses in Miami, Houston, Madrid and Shenzhen through their own domestic agreements.</p>
									
									<p>All of them available for reception of shipments and qualified to assist you with special requests:</p>
									
									<ul
									style={{marginBottom: '20px'}}>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Re-packing</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Cargo audits</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Consolidation</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Documentation control</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Cargo split and break down for re-shipment</li>
									</ul>
									
																		
									<p>The goods must be new, unused and not affected by any third party regulations (Certificate of Origin, Stamp, Electric Security, INAL, SENASA, ANMAT, CHAS, etc.).</p>
									
									<p className="requisitos">ONLY REQUIREMENTS:<br/>
                                    TO BE A NATURAL PERSON OR LEGAL ENTITY WITH A SECURITY LEVEL 3 FISCAL CODE.<br/>
                                    IT IS NOT REQUIRED TO HAVE AN IMPORTER'S LICENSE.</p>
									
									<p>To request a custom quote, please email <br/>
									the following information to impo@raiconet.com:
									</p>
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origin.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Weight (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}										
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Dimensions (cm) or Volumetric Weight <br className="d-none d-sm-block"/>
										(Length x height x width/5000). </span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB Value (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Description of contents.</li>
									</ul>
									
									{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>*/}
									
									<p>Need more information?</p>
									<p className="requisitos"
									style={{ marginBottom: '40px' }}><a href="mailTo:impo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
									
									
								</div>
							</div>
						</div>
				  </motion.section>
				)}
			</AnimatePresence>
		</div>
		<div id="noLimits" className="raicoContainer mt-30">
			<div className="row">
				<div className="col-12 col-sm-6 pr-0">
					<div className={"expTxt bgRed "+(limitsExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-12 col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
									<h2>Courier Carga Service</h2>
								</div>
								
								{/*<p className="text-white"
style={{paddingRight: '0'}}>Servicio de transporte puerta-aeropuerto de cargas aéreas <br/>livianas urgentes, que por sus características de peso,<br/> valor, o tipo de producto, quedan excluidas del<br/> régimen Courier.</p> */}
								
								<p className="text-white"
								style={{paddingRight: '0'}}>Door-to-airport transport service for urgent light air cargo that don't meet the regular courier service specifications such as weight or value limits or type of product.</p>
								
								{/*!limitsExpand && 
								<p className="text-white">Evitamos los costos fijos de manejo de<br/>
								documentación en origen y destino.</p>
								*/}
								
								{!limitsExpand && 
								<div className={"expand text-white "+(limitsExpand ? 'invisible' : '')}
									onClick={() => setLimitsExpand(!limitsExpand)}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
	  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
	</svg>
									MORE
								</div>
								}
								
								<AnimatePresence initial={false}>
									{limitsExpand && (
									  <motion.section
										key="content"
										initial="collapsed"
										animate="open"
										exit="collapsed"
										variants={{
										  open: { opacity: 1, height: "auto" },
										  collapsed: { opacity: 0, height: 0 }
										}}
										transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
									  >
									  <div className="infoExpanded">
									  <p>This service works as a regular courier shipment to Ezeiza International Airport, where it is deconsolidated and redirected to the general cargo sector, proceeding to its clearance through a formal dispatch.</p>

									  <p>You can benefit from the express delivery of the courier service and avoid fixed costs associated with a regular air cargo shipment, such as, shipping under a master airway bill, the load of regular freight documentation, or minimum freight charges.</p>
									  
									  <ul className="beneficios"
									  style={{marginTop: '30px',
									  marginBottom: '20px' }}
									  >
										<li><img src={estrella} alt="" title="" />We also forward all shipment information to the broker to avoid extra storage fees.</li>
										<li><img 
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}} src={estrella} alt="" title="" /><span className="d-sm-inline-block">Want to know more about delivery options and costs from Ezeiza  <br/>to your door?</span></li>
										<li><img src={estrella} alt="" title="" />We make an express break down and delivery of the original documentation the same day your cargo arrives.</li>
									  </ul>
									  
									  <p>Our customer service department will deliver real-time shipment tracking from pickup to delivery. </p>

									  <p>Exclusive confidence!</p>
									  
									  <p className="requisitos"> ONLY REQUIREMENT: BEING AN IMPORTER'S LICENSE HOLDER </p>
									  
									  <p>To request a custom quote, please email <br/>
                                      the following information to impo@raiconet.com:
									</p>
									
									<ul>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origin.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Weight (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}										
										  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Dimensions(cm) or Volumetric Weight<br className="d-none d-sm-block"/>
										(Length x height x width/5000).</span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB Value (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Description of contents.</li>
									</ul>
									
									{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>*/}
									
									<p>Need more information?</p>
									
									<p className="requisitos"
									style={{ marginBottom: '30px' }}><a href="mailTo:aereo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
									
									
									</div>
									  </motion.section>
									)}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
				<div id="" className={"col-12 col-sm-6 expImg noLimits"} 
							  data-aos="zoom-in"								  
							  data-aos-duration="900">
					<div className={limitsExpand ? 'expanded' : ''}></div>
				</div>
			</div>
		</div>
		<div id="carga" className="raicoContainer mt-30">
			<div className="row">
				<div id="expCol" className="col-12 col-sm-6">
					<div className={"expTxt bgRed "+(cargaExpand ? 'serviceExpand' : '')}>
					{/*<div id="expArrow" className="col-sm-1">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
						</div> */}

							<div className="col-sm-11 expDesc"
												data-aos='fade-up'
												data-aos-duration="600" data-aos-once="true">
								<div className="txtDesc txtTarjeta">
								
								<h2>Air Cargo Service</h2></div>
								
								{/*<p className="text-white">Tomamos su carga aérea desde cualquier lugar del<br/>
								mundo.</p>
								<p className="text-white">Coordinamos con rapidez el despacho de sus<br/>
								embarques accediendo a vuelos directos y diarios.</p>
								
								<p className="text-white">Servicio de transporte Aéreo internacional hasta el <br/>aeropuerto, diseñado para tomar sus cargas en<br/> cualquier lugar del mundo.</p>
								*/}
								
								<p className="text-white"
								style={{paddingRight: '0'}}>
								Air transport to the airport. A service designed to take your cargo anywhere in the world.</p>
								
								{!cargaExpand &&
									<div id="expandCarga" className={"expand text-white "+(cargaExpand ? 'invisible' : '')}
										onClick={() => setCargaExpand(!cargaExpand)}
									>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
		  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
		</svg>
										MORE
									</div>
								}
								<AnimatePresence initial={false}>
									{cargaExpand && (
									  <motion.section
										key="content"
										initial="collapsed"
										animate="open"
										exit="collapsed"
										variants={{
										  open: { opacity: 1, height: "auto" },
										  collapsed: { opacity: 0, height: 0 }
										}}
										transition={{ duration: 1.1, ease: [0.04, 0.62, 0.23, 0.98] }}
									  >
									  <div className="infoExpanded">
									  
									  <p>Solid agreements with main worldwide agents allow us to offer the most competitive rates and to arrange your shipments in record time by accessing direct and daily flight frequencies.</p>
									  
									  <p>To optimize consolidated cargo shipments from various vendors, we have warehouses in Miami (MIA), Houston (IAH), Madrid (MAD) and Shenzhen (SZX). All of them enabled for reception of shipments and qualified to assist you with special requests: </p>
									  
									  <ul
									  style={{marginTop: '20px',
									  marginBottom: '30px' }}
									  >
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Re-packing</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Cargo audits</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Consolidation</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Documentation Control</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Cargo split and break down for re-shipment</li>
									  </ul>
									  
									  <ul className="beneficios"
										style={{marginBottom: '20px'}}>
											<li><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /><img src={estrella} alt="" title="" /></li>
										</ul>
									  
									  <ul className="beneficios">
										<li><img src={estrella} alt="" title="" />We also forward all shipment information to the broker to avoid extra storage fees. </li>
										<li><img src={estrella} alt="" title="" />We make an express break down and delivery of the original documentation the same day your cargo arrives.</li>
									  </ul>
									  
									  <p className="requisitos"> ONLY REQUIREMENT: BEING AN IMPORTER'S LICENSE HOLDER</p>
									  
									  <p>To request a custom quote, please email <br/>
                                      the following information to impo@raiconet.com:
									</p>
									
									<ul
									style={{marginTop: '30px',
									  marginBottom: '20px' }}
									>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										Origin.</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Weight (Kg).</li>
										<li><svg
										style={{
											verticalAlign: 'top',
											marginTop: '4px'
										}}
										xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>
										<span className="d-sm-inline-block">Dimensions (cm) or Volumetric Weight <br className="d-none d-sm-block"/>
										(Length x height x width/5000).</span></li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>FOB Value (USD).</li>
										<li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16"><circle cx="6" cy="6" r="6"/></svg>Description of contents.</li>
									</ul>
									
									{/*<p className="requisitos"><a href={cobertura} target="_blank">COBERTURA GEOGRÁFICA Y TIEMPOS DE TRÁNSITO</a></p>*/}
									
									<p>Need more information?</p>
									
									<p className="requisitos"
									style={{ marginBottom: '30px' }}><a href="mailTo:aereo@raiconet.com" target="_blank">WE ARE HERE TO HELP</a></p>
									
									
									</div>
									  </motion.section>
									)}
								</AnimatePresence>
							</div>
					</div>
				</div>
				
					<div id="" className={"col-12 col-sm-6 expImg carga"} 
								  data-aos="zoom-in"								  
								  data-aos-duration="900">
						<div className={cargaExpand ? 'expanded' : ''}></div>
					</div>
			</div>
		</div>
		<Warehouses />
		<img src={cargaExpandedImg} style={{ display: 'none' }} />
  </Layout>
)
}
export default ImportacionPage
